import { Column } from "devextreme/ui/data_grid_types";

export const columns: Column<any, any>[] = [
    {
        dataField: "campaignName",
        caption: "Name",
        width: "280px",
    },
    {
        dataField: "inboundCallsCount",
        caption: "In",
    },
    {
        dataField: "answeredCallsCount",
        caption: "Ans",
    },
    {
        dataField: "shortAnsweredCallsCount",
        caption: "Short Ans",
    },
    {
        dataField: "callbacksCount",
        caption: "Cb",
    },
    {
        dataField: "callsInQueueCount",
        caption: "Qd",
        sortOrder: "desc"
    },
    {
        dataField: "maxWaitQueueTime",
        caption: "Cur Lng Call In Q",
        calculateCellValue(data) {
            return new Date(data.maxWaitQueueTime * 1000).toISOString().substring(11, 19);
        },
    },
    {
        dataField: "abandonedCallsCount",
        caption: "Abd",
    },
    {
        dataField: "shortAbandonedCallsCount",
        caption: "Short Abd",
    },
    {
        calculateCellValue(data) {
            const val = data.abandonedCallsCount / data.inboundCallsCount * 100.0;
            return Number.isNaN(val) ? '-' : val.toFixed(2) + '%';
        },
        caption: "Abd %",
    },
    {
        dataField: "averageSpeedOfAnswer",
        calculateCellValue(data) {
            return new Date(data.averageSpeedOfAnswer * 1000).toISOString().substring(11, 19);
        },
        caption: "ASA",
    },
    {
        dataField: "speedOfAnswer",
        calculateCellValue(data) {
            const val = data.speedOfAnswer * 100.0;
            return Number.isNaN(val) ? '-' : val.toFixed(2) + '%';
        },
        caption: "SOA",
    },
    {
        dataField: "averageHandleTime",
        calculateCellValue(data) {
            return new Date(data.averageHandleTime * 1000).toISOString().substring(11, 19);
        },
        caption: "AHT",
    },
    {
        dataField: "occupancyRate",
        caption: "Ocp %",
        calculateCellValue(data) {
            const val = data.occupancyRate * 100.0;
            return Number.isNaN(val) ? '-' : val.toFixed(2) + '%';
        },
    },
    {
        dataField: "loggedAgentsCount",
        caption: "Lgd",
    },
    {
        dataField: "loggedOtherCount",
        caption: "Lgd Other",
    },
    {
        dataField: "emailsInQueueCount",
        caption: "Email",
    },
    {
        dataField: "internalTransfer",
        caption: "Internal Transfers",
    },
    {
        dataField: "externalTransfer",
        caption: "External Transfers",
    },
    {
        dataField: "answered5MinMorePct",
        caption: "Ans > 5m (%)",
        calculateCellValue(data) {
            const val = data.answered5MinMorePct * 100.0;
            return Number.isNaN(val) ? '-' : val.toFixed(2) + '%';
        },
    },
];