import { useEffect, useState } from "react";
import { DataGrid, Toast } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import { columns } from "../lib/metrics";
import { loginRequest } from "../auth-config";
import { ToastType } from "devextreme/ui/toast";
import { useAuth } from "../hooks/useAuth";
import { CellPreparedEvent } from "devextreme/ui/data_grid";

const cellDataLimits: any = {
    "SOA": {
        "warn": 75,
        "danger": 65,
        comparison: (a: any, b: any) => Number.parseFloat(a) < b,
    },
    "Abd %": {
        "warn": 6,
        "danger": 8,
        comparison: (a: any, b: any) => Number.parseFloat(a) > b,
    },
    "Ocp %": {
        "warn": 70,
        "danger": 65,
        comparison: (a: any, b: any) => Number.parseFloat(a) < b,
    }
};

function cellCustomization(e: CellPreparedEvent) {
    if (e.rowType === "data") {
        const key = e.column.caption;
        if (!key) return
        const limits = cellDataLimits[key];
        if (limits !== undefined && limits.comparison !== undefined) {
            if (limits.danger !== undefined && limits.comparison(e.value, limits.danger)) {
                e.cellElement.classList.add("bg-danger");
                e.cellElement.classList.add("text-white");
            }
            else if (limits.warn !== undefined && limits.comparison(e.value, limits.warn)) {
                e.cellElement.classList.add("bg-warning");
                e.cellElement.classList.add("text-white");
            }
        }
    }
}

export const Metrics = () => {
    const token = useAuth(loginRequest);
    const [dataStore, setDataStore] = useState<CustomStore>();
    const [updatedAt, setUpdatedAt] = useState<Date>();
    const [toastConfig, setToastConfig] = useState<{ isVisible: boolean, message: string, type: ToastType }>({
        isVisible: false,
        message: '',
        type: 'info',
    });

    const showError = (message: string) => {
        setToastConfig({
            isVisible: true,
            message,
            type: 'error',
        });
    };

    useEffect(() => {
        function updateDataStore() {
            if (!token) return;
            fetch(`${process.env.REACT_APP_METRICS_API_URL}/live`, {
                headers: {
                    "x-auth": `Bearer ${token}`,
                }
            }).then(async r => {
                if (r.ok) {
                    let data = await r.json();
                    setUpdatedAt(new Date(data.updatedAt));
                    setDataStore(data.metrics);
                } else {
                    console.error(r.statusText);
                    showError('There was an error while retrieving the data.');
                }
            }).catch(e => {
                console.log(e);
                showError('There was an error while retrieving the data.');
            });
        }

        updateDataStore();
        let timeout: any;
        function updateData() {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                if (token) {
                    updateDataStore();
                }
                updateData();
            }, process.env.REACT_APP_REFRESH_INTERVAL_MS ?? 20000);
        }
        updateData();
        return () => clearTimeout(timeout);
    }, [token]);

    return (
        <>
            <Toast visible={toastConfig.isVisible} message={toastConfig.message} type={toastConfig.type}
                onHiding={() => setToastConfig({ ...toastConfig, isVisible: false })}
            />
            <DataGrid
                dataSource={dataStore} columns={columns} remoteOperations={false}
                allowColumnReordering={true} rowAlternationEnabled={true} onCellPrepared={cellCustomization}
                showBorders={true} allowColumnResizing={true} loadPanel={{ enabled: false, }}
                wordWrapEnabled={true} stateStoring={{ enabled: true, storageKey: 'dx-state-v3', type: 'localStorage' }}
            />
            <span>{updatedAt?.toLocaleString()}</span>
        </>
    )
};