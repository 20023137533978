import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth-config";

export const SignOutButton = () => {
    const { instance } = useMsal();

    return (
        <div>
            <button
                onClick={(_) => instance.logout(loginRequest).catch(e => console.error(e))}
                color="inherit"
                className="btn btn-link navbar-brand"
            >
                Logout
            </button>
        </div >
    )
}