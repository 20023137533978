import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "./auth/SignInButton";
import { SignOutButton } from "./auth/SignOutButton";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "../auth-config";
import { useEffect } from "react";
import { Link } from "react-router-dom";

type Props = {
    children?: React.ReactNode;
};

export function NavBar({ children }: Props) {
    const { inProgress, instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();


    useEffect(() => {
        if (accounts[0]) {
            instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            }).then(r => instance.setActiveAccount(r.account));
        }
    }, [inProgress, instance, accounts]);


    const SignInOut = () => {
        if (isAuthenticated) {
            return <SignOutButton />;
        } else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
            return <SignInButton />;
        } else {
            return null;
        }
    }

    const NavLinks = () => (
        <>
            <li className="nav-item"><Link to="/bi" className="nav-link">Power BI</Link></li>
            <li className="nav-item"><Link to="/metrics" className="nav-link">Metrics</Link></li>
        </>
    )

    return (
        <div style={{ paddingTop: '5rem' }}>
            <nav className="navbar bg-dark fixed-top navbar-expand-md" data-bs-theme="dark">
                <Link to="/" className="btn btn-link navbar-brand">Alpha Bank Wallboard</Link>
                <SignInOut />

                <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbar"
                    aria-expanded="false" aria-controls="navbar">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div id="navbar" className="collapse navbar-collapse">
                    <ul className="navbar-nav mr-auto">
                        {isAuthenticated && <NavLinks />}
                    </ul>
                </div>
            </nav >
            <main role="main" className="container-fluid">
                {children}
            </main>
        </div>
    );
};