import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: "/",
        postLogoutRedirectUri: "/",
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
};

export const loginRequest: PopupRequest = {
    scopes: process.env.REACT_APP_LOGIN_SCOPES.split(','),
};

export const powerBILoginRequest: PopupRequest = {
    scopes: process.env.REACT_APP_POWER_BI_SCOPES.split(','),
};