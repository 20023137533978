import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth-config";

export const SignInButton = () => {
    const { instance } = useMsal();

    return (
        <div>
            <button
                onClick={(_) => instance.loginPopup(loginRequest).then(r => instance.setActiveAccount(r.account)).catch(e => console.error(e))}
                color="inherit"
                className="btn btn-link navbar-brand"
            >
                Login
            </button>
        </div>
    )
}