import { useIsAuthenticated } from "@azure/msal-react";
import { useAuth } from "../hooks/useAuth";
import { useEffect, useState } from "react";
import { powerBILoginRequest } from "../auth-config";
import { models, service, Report, Embed } from 'powerbi-client';
import { PowerBIEmbed } from "powerbi-client-react";
import { Toast } from "devextreme-react";
import { ToastType } from "devextreme/ui/toast";

export type Props = {
    embedUrl: string,
    reportId: string,
};

export const PowerBIReport = ({ embedUrl, reportId }: Props) => {
    const isAuthenticated = useIsAuthenticated();
    const token = useAuth(powerBILoginRequest);
    const [report, setReport] = useState<Report>();

    const [isEmbedded, setIsEmbedded] = useState<boolean>(false);

    const [reportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
        type: 'report',
        id: reportId,
        embedUrl: embedUrl,
        tokenType: models.TokenType.Aad,
        accessToken: undefined,
    });

    const [toastConfig, setToastConfig] = useState<{ isVisible: boolean, message: string, type: ToastType }>({
        isVisible: false,
        message: '',
        type: 'info',
    });

    const showError = (message: string) => {
        setToastConfig({
            isVisible: true,
            message,
            type: 'error',
        });
    };

    const [eventHandlersMap] = useState<Map<string, (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null>>(new Map([
        ['loaded', () => console.log('Report has loaded')],
        ['rendered', () => console.log('Report has rendered')],
        ['error', (event?: service.ICustomEvent<any>) => {
            if (event) {
                console.error(event.detail);
                if (event?.detail?.detailedMessage)
                    showError(event.detail.detailedMessage);
            }
        },
        ],
        ['visualClicked', () => { }],
        ['pageChanged', (event) => { }],
    ]));

    useEffect(() => {
        if (report) {
            report.setComponentTitle('Embedded Report');
        }
    }, [report]);

    useEffect(() => {
        if (token) {
            setIsEmbedded(true);

            setReportConfig({
                ...reportConfig,
                accessToken: token,
            })
        }
    }, [token, reportConfig]);

    return (
        <>
            <Toast visible={toastConfig.isVisible} message={toastConfig.message} type={toastConfig.type} />
            {isAuthenticated && isEmbedded &&
                <PowerBIEmbed embedConfig={reportConfig} eventHandlers={eventHandlersMap} cssClassName='report-container' getEmbeddedComponent={
                    (embedObject: Embed) => {
                        setReport(embedObject as Report);
                    }
                } />
            }
        </>
    )
};