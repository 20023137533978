import './App.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'devextreme/dist/css/dx.light.css';

import { Routes, Route } from "react-router-dom";
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { NavBar } from "./components/NavBar";
import { PowerBI } from "./pages/PowerBI";
import { Metrics } from "./pages/Metrics";

type AppProps = {
  pca: IPublicClientApplication
};

function App({ pca }: AppProps) {
  return (
    <MsalProvider instance={pca}>
      <NavBar>
        <Pages />
      </NavBar>
    </MsalProvider>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path="/" element={<div />}></Route>
      <Route path="/bi" element={<PowerBI />}></Route>
      <Route path="/metrics" element={<Metrics />}></Route>
    </Routes>
  )
}

export default App;
