import { AccountInfo, AuthenticationResult, IPublicClientApplication, InteractionRequiredAuthError, InteractionStatus, PopupRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";

async function login(instance: IPublicClientApplication, request: PopupRequest, inProgress: InteractionStatus) {
    if (inProgress === InteractionStatus.None) {
        const account = instance.getActiveAccount();
        if (!account) {
            const result = await instance.acquireTokenPopup(request);
            return result;
        } else {
            try {
                const result = await instance.acquireTokenSilent({
                    ...request,
                    account,
                });
                return result;
            } catch (e) {
                console.error(e);
                if (e instanceof InteractionRequiredAuthError) {
                    const result = await instance.acquireTokenPopup({
                        ...request,
                        account: instance.getActiveAccount() as AccountInfo
                    });
                    return result;
                }
            }
        }
    }
}

export const useAuth = (loginRequest: PopupRequest) => {
    const { instance, inProgress } = useMsal();
    const [authResult, setAuthResult] = useState<null | AuthenticationResult>(null);

    useEffect(() => {
        const getResult = () => {
            login(instance, loginRequest, inProgress).then((r) => {
                setAuthResult(r ?? null);
                if (r?.expiresOn) {
                    setTimeout(getResult, r.expiresOn.getTime() - new Date().getTime())
                }
            });

        };

        getResult();
    }, [inProgress, instance, loginRequest]);

    return authResult?.accessToken;
}